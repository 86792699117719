import { PrismicPageParser } from "../../shared/utils/prismicPageParser"
import cx from "classnames"
import styles from "./GivingBackContainer.module.scss"

const BCorpContainer = () => {
  const data = JSON.parse(window.prismic_data)
  const pageId = window.pageId

  return (
    <section
      id="mm-bcorp"
      className={cx(styles.givingBack, "pageContainer")}
      aria-label="Certified B Corporation"
    >
      <PrismicPageParser pageData={data} pageId={pageId} />
    </section>
  )
}

export default BCorpContainer

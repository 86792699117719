import React from "react"
import CloseIcon from "../../../icons/CloseIcon"
import DownloadIcon from "../../../icons/DownloadIcon"

const DownloadRoundNotice = ({ downloadState, handleCloseNotice }) => {
  const { downloadMessage, downloadNotice, downloadProcessing, url } = downloadState
  const message = downloadMessage || "Your download is ready!"

  if (downloadNotice) {
    if (downloadProcessing) {
      return (
        <div className="download-notice processing">
          <p>
            {downloadMessage || "Your download is processing."}
            <span className="spinner" />
          </p>
        </div>
      )
    } else {
      return (
        <div className={`download-notice ${downloadMessage ? "timeout" : "ready"}`}>
          <span className="close" onClick={handleCloseNotice}>
            <CloseIcon />
          </span>
          <p>{message}</p>
          {!downloadMessage && url && (
            <a href={`${url}`} target="_blank">
              <span className="icon">
                <DownloadIcon />
              </span>
            </a>
          )}
        </div>
      )
    }
  } else {
    return null
  }
}

export default DownloadRoundNotice

import * as prismic from "@prismicio/client"

const endpoint = prismic.getRepositoryEndpoint("marmosetmusic")
export const PrismicClient = prismic.createClient(endpoint, {
  accessToken: process.env.PRISMIC_ACCESS_TOKEN,
})

export const linkResolver = (doc) => {
  if (doc.uid.includes("draft")) {
    return mm.router.navigate(`/draft/${doc.id}`, { trigger: true })
  }
  switch (doc.uid) {
    case "home":
      return mm.router.navigate("/", { trigger: true })
    case "dev_test_home":
      return mm.router.navigate("/prismic_dev_test_home", { trigger: true })
    case "artist-collabs":
      return mm.router.navigate("/collaborations/artist-collabs", { trigger: true })
    case "artist-residency-house":
      return mm.router.navigate("/collaborations/artist-residency-house", { trigger: true })
    case "label-partnerships":
      return mm.router.navigate("/collaborations/label-partnerships", { trigger: true })
    case "anthology":
      return mm.router.navigate("/collaborations/anthology", { trigger: true })
    case "purpose":
      return mm.router.navigate("/about/purpose", { trigger: true })
    case "artists":
      return mm.router.navigate("/about/artists", { trigger: true })
    case "giving-back":
      return mm.router.navigate("/about/giving-back", { trigger: true })
    case "bcorp":
      return mm.router.navigate("/about/bcorp", { trigger: true })
    case "team":
      return mm.router.navigate("/about/team", { trigger: true })
    case "careers":
      return mm.router.navigate("/about/careers", { trigger: true })
    case "podcast-license":
      return mm.router.navigate("/music-licensing/podcast-license", { trigger: true })
    case "reel-license":
      return mm.router.navigate("/music-licensing/reel-license", { trigger: true })
    case "wedding-license":
      return mm.router.navigate("/music-licensing/wedding-license", { trigger: true })
    default:
      return mm.router.navigate(`/${doc.uid}`, { trigger: true })
  }
}
